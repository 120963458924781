import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from '@emotion/styled';

const pathUtil = require('../util/PathUtil');

const Showcase = ({ showCaseEdges, imgSrc, title, description, tag, link }) => {
  return (
    <>
      {showCaseEdges.map((edge, i) => {
        const link = pathUtil.GeneratePath(
          edge.node.frontmatter.created,
          edge.node.frontmatter.path_title,
          edge.node.frontmatter.lan
        );
        const ShowCaseHeader = styled('div')`
          color: #fff;
          background: #333;
          padding: 2rem;
          position: relative;
          &:before {
            content: '';
            background: url('${edge.node.frontmatter.feat_img.childImageSharp.fluid.src}') no-repeat center center/cover; 
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        `;
        return (
          <ShowCaseHeader key={i} className="mySlides">
            <div className="container">
              <div className="showcase-container ">
                <div className="showcase-content">
                  <div
                    className={`category category-${edge.node.frontmatter.tags[0]}`}
                  >
                    {edge.node.frontmatter.tags[0] === 'foodanddrink'
                      ? 'Food and Drink'
                      : edge.node.frontmatter.tags[0]}
                  </div>
                  <h1>{edge.node.frontmatter.title}</h1>
                  <p>{edge.node.frontmatter.description}</p>
                  <Link to={link} className="btn btn-primary">
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </ShowCaseHeader>
        );
      })}
      {/* <div style={{ textAlign: 'center' }}>
        {showCaseEdges.map((edge, i) => (
          <span key={i} className="dot"></span>
        ))}
      </div> */}
    </>
  );
};
const BlogCard = ({
  topImgSrc,
  botImgSrc,
  title,
  description,
  tag,
  link,
  backgroundColor,
}) => (
  <article className={`card ${backgroundColor}`}>
    {topImgSrc && (
      <Link to={link}>
        <img src={topImgSrc} alt="" />
      </Link>
    )}
    <div>
      <div className={`category category-${tag}`}>
        {tag === 'foodanddrink' ? 'Food and Drink' : tag}
      </div>
      <h3>
        <Link to={link}>{title}</Link>
      </h3>
      <p>{description}</p>
    </div>
    {botImgSrc && (
      <Link to={link}>
        <img src={botImgSrc} alt="" />
      </Link>
    )}
  </article>
);
const BlogPanel = ({ markdownEdges }) => {
  const cardSettings = [
    { topImg: true, botImg: false, backgroundColor: null },
    { topImg: false, botImg: false, backgroundColor: 'bg-dark' },
    { topImg: true, botImg: false, backgroundColor: null },
    { topImg: false, botImg: true, backgroundColor: null },
    { topImg: true, botImg: false, backgroundColor: null },
    { topImg: false, botImg: true, backgroundColor: null },
    { topImg: true, botImg: false, backgroundColor: null },
    { topImg: false, botImg: true, backgroundColor: null },
    { topImg: false, botImg: false, backgroundColor: 'bg-primary' },
    { topImg: true, botImg: false, backgroundColor: null },
  ];
  const offset = 0;

  return (
    <section id="home-articles">
      <div className="container">
        <br></br>
        <h2>Blogs</h2>
        <div className="articles-container">
          {cardSettings.map((setting, i) => {
            const index = i + offset;

            const link = pathUtil.GeneratePath(
              markdownEdges[index].node.frontmatter.created,
              markdownEdges[index].node.frontmatter.path_title,
              markdownEdges[index].node.frontmatter.lan
            );
            return (
              <BlogCard
                key={index}
                topImgSrc={
                  setting.topImg &&
                  markdownEdges[index].node.frontmatter.feat_img.childImageSharp
                    .fluid.src
                }
                botImgSrc={
                  setting.botImg &&
                  markdownEdges[index].node.frontmatter.feat_img.childImageSharp
                    .fluid.src
                }
                title={markdownEdges[index].node.frontmatter.title}
                description={markdownEdges[index].node.frontmatter.description}
                tag={markdownEdges[index].node.frontmatter.tags[0]}
                link={link}
                backgroundColor={setting.backgroundColor}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};
const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title={process.env.GATSBY_LANGUAGE === 'th' ? 'หน้าแรก' : 'Home'}
      lang={process.env.GATSBY_LANGUAGE}
    />
    <Showcase
      showCaseEdges={data.allMarkdownRemark.edges.filter(
        edge => edge.node.frontmatter.showcase === true
      )}
    />
    <BlogPanel markdownEdges={data.allMarkdownRemark.edges} />
  </Layout>
);
export const pageQuery = graphql`
  query($lan: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { lan: { eq: $lan }, include: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___created] }
    ) {
      edges {
        node {
          id
          frontmatter {
            path_title
            created
            expire
            include
            tags
            title
            author
            lan
            description
            showcase
            feat_img {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;
export default IndexPage;
